<template lang="pug">
include ../../../configs/template.pug
v-dialog(v-model="modal" max-width="500")
  v-card
    v-card-title {{ $t('uploadPaymentReceipt') }}

    v-card-text(v-if="selectedDocument").text-left
      div.w-100.px-0
        +data-info('typeDoc', 'selectedDocument.type_document_name')
      div.w-100.px-0
        +data-info('appointment', 'selectedDocument.payment_info.payment_due')
      //- ViewPhotoList(v-if="selectedDocument.photo.length" :sailorDocument="selectedDocument" :allowDelete="false")
        //- v-if="selectedDocument.photo.length"
      ViewFileList(
        ref="fileList"
        :files="selectedDocument.photo"
        :allowDelete="false"
        isHiddenButton).warning--text.mt-2
      //- div.warning--text.mt-2(v-else) {{ $t('documentNotFound') }}
      FileDropZone(ref="mediaContent").mt-3

    v-card-actions.d-flex.justify-end
      v-btn(text @click="modal = false") {{ $t('back') }}
      v-btn(
        :loading="isUploadLoading"
        color="success"
        @click="uploadPaymentReceipt") {{ $t('uploadDocs') }}
</template>

<script>
import { mapActions } from 'vuex'
import { FileToBase64 } from '@/mixins/main'

export default {
  props: {
    selectedDocument: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue'),
    ViewFileList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue')
  },
  data () {
    return {
      modal: false,
      isUploadLoading: false
    }
  },
  watch: {
    modal (value) {
      if (!value && this.$refs.mediaContent) this.$refs.mediaContent.removeAllFile()
    }
  },
  methods: {
    ...mapActions(['uploadPositionStatementPaymentReceipt']),

    uploadPaymentReceipt () {
      const files = this.$refs.mediaContent.filesArray
      if (!files.length) return false

      const formData = new FormData()
      files.forEach(file => formData.append('files', file))

      this.isUploadLoading = true
      this.uploadPositionStatementPaymentReceipt({ documentID: this.$route.params.documentID, selectDocumentId: this.selectedDocument.id, body: formData })
        .then(async response => {
          console.log('response :>> ', response)
          if (response?.code === 201) {
            let newFiles = await Promise.all(
              files.map(async el => {
                let response = await FileToBase64(el)
                return response
              })
            )
            this.$refs.fileList && (this.$refs.fileList.listFile = [...this.$refs.fileList.listFile, ...newFiles])
            this.$notification.success('notify.success.receiptAdd')
            this.modal = false
          }
        }).finally(() => { this.isUploadLoading = false })
    }
  }
}
</script>
